
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseIcon from './BaseIcon.vue'
import BaseLink from './BaseLink.vue'
import { ILink } from '../../shared/general/interfaces/ILink'
import { trackCta } from '../../shared/general/tracking/TrackingService'

@Component({
  name: 'BaseCta',
  components: {
    BaseIcon,
    BaseLink,
  },
})
export default class BaseCta extends Vue {
  @Prop({ required: true }) link! : ILink

  private click () : void {
    trackCta(this.$el as HTMLElement, {
      link_text: this.link?.label || '',
      link_url: this.link?.url || '',
      icon_name: this.link?.iconName || '',
      cta_type: 'cta_content',
    })
  }
}
